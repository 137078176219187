import { useState, useEffect } from 'react';
import {Col, Row, Button, Dropdown, Space, message} from 'antd';
import { encryption, setCookie, getCookie, clearCookie } from '@/utils'
import { observer } from 'mobx-react-lite'
import './index.scss'
import logoHeader from '@/assets/images/header/logoHeader_w.png'
import logoHeader_ from '@/assets/images/header/logoHeader.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useStore } from '@/store'
import { DownOutlined } from '@ant-design/icons';
import {http} from "@/utils";
import top from "@/assets/images/Entrance/039 Deep Blue(1).png";


function Header() {
    //获取路由地址
    const { pathname } = useLocation()
    // 编辑文章 loginStore.loginPopup
    const navigate = useNavigate()
    const { loginStore, userStore } = useStore()
    const signUp = () => {
        loginStore.loginPopup(true)
    }
    const Logout = async () => {
        const authData = await http.delete("/auth/token/logout");
        if (authData.data.code == 0) {
            clearCookie('auth');
            userStore.clearUserInfo()
            navigate('/')
        }
    }
    const items = [
        {
            label: (
                <Link to={'/Personal/AccountInformation'}>
                    Personal Center
                </Link>
            ),
            key: '0',
        },
        {
            label: (
                <span onClick={Logout}>
                    Logout
                </span>
            ),
            key: '1',
        }
    ]
    return(
        <div className={['header', pathname != '/DownloadCenter' && pathname != '/'? 'actives' : ''].join(' ')}>
            {
                pathname == '/' ? <img className={'top'} src={top} alt=""/> : null
            }
            <Row style={{ width: '100%' }}>
                <Col span={8}>
                    {
                        pathname === '/' ? <img onClick={() => navigate('/')} className={'logoHeader'} src={logoHeader} alt=""/> :
                            <img onClick={() => navigate('/')} className={'logoHeader'} src={logoHeader_} alt=""/>
                    }

                </Col>
                <Col style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }} span={16}>
                    <ul>
                        <li className={pathname == '/ProductList' ? 'active' : null} onClick={() => navigate('/ProductList')}>Products</li>
                        <li className={pathname == '/DownloadCenter' ? 'active' : null} onClick={() => navigate('/DownloadCenter')}>Resources</li>
                        <li className={pathname == '/OrderList' ? 'active' : null} onClick={() =>   navigate('/OrderList')}>Order</li>
                        <li className={pathname == '/About' ? 'active' : null} onClick={() => navigate('/About')}>About</li>
                    </ul>
                    {
                        userStore.userInfo ? <div className={'count'}>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {
                                            userStore.userInfo.vipType && userStore.userInfo.vipType != 'COMMON' ?
                                                <img style={{marginTop: '3px'}} src={require(`../../assets/images/header/${userStore.userInfo.vipType}.png`)} alt=""/> :
                                                null
                                        }

                                        {userStore.userInfo.email}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div> :
                        <Button className={'Sign'} onClick={ signUp } type="primary" shape="circle">
                        Sign up
                        </Button>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default observer(Header)
