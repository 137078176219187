//登陆模块
import { makeAutoObservable } from "mobx"
import { http,  } from "@/utils";

class UserStore{
    userInfo = ''
    constructor() {
        makeAutoObservable(this)
    }
    getUserInfo(){
        const res = http.get('/admin/user/current').then(res => {
            this.userInfo = res.data.data
        })
    }
    clearUserInfo() {
        this.userInfo = ''
    }
}

export default UserStore
