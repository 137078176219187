import {makeAutoObservable} from 'mobx'
import {http} from '@/utils'

class queryStore {
    pieData = []
    pieDatas = []
    trendsData = {
        "legendYear": [
        ],
        "applications": [
        ]
    }

    constructor() {
        makeAutoObservable(this)
    }

    // article publish 哪里调用这个函数呢？
    pieDataEvent = (data) => {
        this.pieData = data
    }
    pieDatasEvent = (data) => {
        this.pieDatas = data
    }
    trendsDataEvent = (obj) => {
        this.trendsData = obj
    }
}

export default queryStore
