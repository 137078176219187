import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'antd/dist/reset.css';

import './index.scss';
import '@/assets/css/animate.css'
import '@/assets/css/style.scss'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
