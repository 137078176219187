import React from 'react'
import LoginStore from "@/store/login.Store";
import UserStore from "@/store/user.Store";
import ChannelStore from "@/store/channel.Store";
import QueryStore from "@/store/query.Store";

import { configure } from "mobx"
configure({
    enforceActions: "never",
})

class RootStore {
    //组合模块
    constructor() {
        this.loginStore = new LoginStore()
        this.userStore = new UserStore()
        this.channelStore = new ChannelStore()
        this.queryStore = new QueryStore()
    }
}

// 实例化根
// 导出useStore context
const rootStore = new RootStore()
const context = React.createContext(rootStore)

const useStore = () => React.useContext(context)

export { useStore }
