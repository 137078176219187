//导入路由
import { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'
import { HistoryRouter, history } from './utils/history'
import './App.css'
import  AuthComponent  from "@/components/AuthComponent";
import { ConfigProvider, Spin  } from 'antd';
import { getCookie } from '@/utils'
import {useStore} from '@/store'
import {observer} from "mobx-react-lite"
//引入Bus总线
import eventBus from '@/utils/eventBus'
//页面加载loading
import Loading from "@/components/Loading";

//首页
const Entrance =  lazy(() => import("@/pages/Entrance"));
//商标查询页
const Query =  lazy(() => import("@/pages/Query"));
//专利翻译
const Trans =  lazy(() => import("@/pages/Trans"));
//订单服务页面
const Order =  lazy(() => import("@/pages/Order"));
//资源下载页
const DownloadCenter = lazy(() => import("@/pages/DownloadCenter"));
//关于我们
const About = lazy(() => import("@/pages/About"));
//产品
const Product = lazy(() => import("@/pages/Product"));
//注册协议 ServiceTreaty
const ServiceTreaty = lazy(() => import("@/pages/ServiceTreaty"));
//个人中心
const Personal = lazy(() => import("@/pages/Personal"));
//个人中心的字内容
const AccountInformation = lazy(() => import("@/pages/Personal/AccountInformation"));
const MyInvoice = lazy(() => import("@/pages/Personal/MyInvoice"));
const MyOrder = lazy(() => import("@/pages/Personal/MyOrder"));
const MyServices = lazy(() => import("@/pages/Personal/MyServices"));
const PurchaseServices = lazy(() => import("@/pages/Personal/PurchaseServices"));
const TrademarkLog = lazy(() => import("@/pages/Personal/TrademarkLog"));
const TranslationLog = lazy(() => import("@/pages/Personal/TranslationLog"));

//商标查询页面
const Trademark = lazy(() => import("@/pages/Query/Trademark"));
//商标查询的子内容
const Comparison = lazy(() => import("@/pages/Query/Comparison"));
const GoodServices = lazy(() => import("@/pages/Query/GoodServices"));
const ImageSearch = lazy(() => import("@/pages/Query/ImageSearch"));
const Monitor = lazy(() => import("@/pages/Query/Monitor"));
const MonitorReport = lazy(() => import("@/pages/MonitorReport"));

//商标查询详情
const Detail = lazy(() => import("@/pages/Query/Detail"));
const OtherTrademarkArchives = lazy(() => import("@/pages/Query/OtherTrademarkArchives"));
//订单付款页
const OrderInfo = lazy(() => import("@/pages/Pay/OrderInfo"));
//对公支付页面
const Payment = lazy(() => import("@/pages/Pay/Payment"));
//展示PDF文件页面
const Pdf = lazy(() => import("@/pages/Pdf"));
//修改密码
const ChangePassword = lazy(() => import("@/pages/ChangePassword"));
//对公支付落地成功页
const UploadSuccess = lazy(() => import("@/pages/Pay/UploadSuccee"));
//免费试用成功落地页
const PaySuccess = lazy(() => import("@/pages/Pay/PaySuccess"));
//FAQ
const FAQ = lazy(() => import("@/pages/FAQ"));
//服务条约
const PrivacyPolicy = lazy(() => import("@/pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("@/pages/RefundPolicy"));
//404页面
const Loss = lazy(() => import("@/pages/Loss"));

function App() {
    const {userStore, loginStore} = useStore()
    //获取用户信息
    const getUserInfo = () => {
        if (getCookie("auth")) {
            userStore.getUserInfo();
        } else {
            userStore.clearUserInfo();
        }
    }

    useEffect(() => {
        eventBus.addListener('open', () => {
            loginStore.loginPopup(true)
        });
    }, [])

    useEffect(() => {
        getUserInfo()
    },[])

  return (
      <HashRouter history={ history }>
          <ConfigProvider
              theme={{
                  token: {
                      colorPrimary: '#004AFF',
                  },
              }}
          >
                 <div className="App">
            <Suspense
                fallback={
                    <Loading/>
                }
            >

            <Routes>
                {/*需要鉴权的路由*/}
                {/*<Route path='/' element={*/}
                {/*    <AuthComponent>*/}
                {/*        <Layout/>*/}
                {/*    </AuthComponent>*/}
                {/*}*/}
                {/*>*/}
                {/*    /!*二级路由默认页面*!/*/}
                {/*    <Route index element={<Home/>} />*/}
                {/*    <Route path={'article'} element={<Article/>} />*/}
                {/*    <Route path={'publish'} element={<Publish/>} />*/}
                {/*</Route>*/}
                {/*不需要鉴权的路由*/}
                <Route index path='/' element={
                    <AuthComponent >
                        <Entrance/>
                    </AuthComponent >
                }/>
                <Route path='/PQ' element={
                    <AuthComponent>
                        <Query/>
                    </AuthComponent>
                }>
                    <Route path='/PQ/Trademark' element={
                        <Trademark/>}
                    />
                    <Route path='/PQ/Comparison' element={
                        <Comparison/>}
                    />
                    <Route path='/PQ/GoodServices' element={
                        <GoodServices/>}
                    />
                    <Route path='/PQ/ImageSearch' element={
                        <ImageSearch/>}
                    />
                    <Route path='/PQ/Monitor' element={
                        <Monitor/>}
                    />
                </Route>
                <Route path='/PQ/MonitorReport' element={
                    <AuthComponent>
                        <MonitorReport/>
                    </AuthComponent>
                    }
                />
                {/*Detail*/}
                <Route path='/PQ/Detail' element={
                    <AuthComponent>
                        <Detail/>
                    </AuthComponent>
                }/>
                <Route path='/PQ/OtherTrademarkArchives' element={
                    <OtherTrademarkArchives/>}
                />
                <Route
                    path='/PT'
                    element={
                        <AuthComponent>
                            <Trans/>
                        </AuthComponent>
                    }
                />
                <Route path='/OrderList' element={
                    <AuthComponent>
                        <Order/>
                    </AuthComponent>
                }/>
                <Route path='/Pdf' element={
                    <AuthComponent>
                        <Pdf/>
                    </AuthComponent>
                }/>
                <Route path='/ChangePassword' element={
                    <AuthComponent>
                        <ChangePassword/>
                    </AuthComponent>
                }/>
                <Route path='/redirect/orderInfo' element={
                    <AuthComponent>
                        <OrderInfo/>
                    </AuthComponent>
                }/>
                <Route path='/payment' element={
                    <AuthComponent>
                        <Payment/>
                    </AuthComponent>
                }/>
                <Route path='/uploadSuccess' element={
                    <AuthComponent>
                        <UploadSuccess/>
                    </AuthComponent>
                }/>
                <Route path='/redirect/paySuccess' element={
                    <AuthComponent>
                        <PaySuccess/>
                    </AuthComponent>
                }/>
                <Route path='/DownloadCenter' element={
                    <AuthComponent>
                        <DownloadCenter/>
                    </AuthComponent>
                }/>
                <Route path='/About' element={
                    <AuthComponent>
                        <About/>
                    </AuthComponent>
                }/>
                <Route path='/FAQ' element={
                    <AuthComponent>
                        <FAQ/>
                    </AuthComponent>
                }/>
                <Route path='/PrivacyPolicy' element={
                    <AuthComponent>
                        <PrivacyPolicy/>
                    </AuthComponent>
                }/>
                <Route path='/RefundPolicy' element={
                    <AuthComponent>
                        <RefundPolicy/>
                    </AuthComponent>
                }/>
                <Route path='/ProductList' element={
                    <AuthComponent>
                        <Product/>
                    </AuthComponent>
                }/>
                <Route path='/ServiceTreaty' element={
                    <AuthComponent>
                        <ServiceTreaty/>
                    </AuthComponent>
                }/>
                <Route path='/Personal' element={
                    <AuthComponent>
                        <Personal/>
                    </AuthComponent>
                }>
                    <Route index path='/Personal/AccountInformation' element={
                        <AccountInformation/>
                    }/>
                    <Route path='/Personal/MyInvoice' element={
                        <MyInvoice/>
                    }/>
                    <Route path='/Personal/MyOrder' element={
                        <MyOrder/>
                    }/>
                    <Route path='/Personal/MyServices' element={
                        <MyServices/>
                    }/>
                    <Route path='/Personal/PurchaseServices' element={
                        <PurchaseServices/>
                    }/>
                    <Route path='/Personal/TrademarkLog' element={
                        <TrademarkLog/>
                    }/>
                    <Route path='/Personal/TranslationLog' element={
                        <TranslationLog/>
                    }/>
                </Route>
                <Route path='*' element={
                    <Loss/>
                }/>
            </Routes>
            </Suspense>
        </div>
          </ConfigProvider>
      </HashRouter>
  )
}

export default observer(App)
