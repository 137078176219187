// 1. 判断token是否存在
// 2. 如果存在 直接正常渲染
// 3. 如果不存在 重定向到登录路由

// 高阶组件:把一个组件当成另外一个组件的参数传入
// 然后通过一定的判断 返回新的组件
import { encryption, setCookie, getCookie, clearCookie, queryURLParameter } from '@/utils'
import qs from 'qs'
import {Navigate, useLocation} from 'react-router-dom'
import {InfoCircleOutlined, UserOutlined} from '@ant-design/icons';
import Header from "@/components/Header";
import Loading from "@/components/Loading"
import {
    Spin, Modal, Checkbox, Form, Input, Button, Divider, message, AutoComplete,
    Cascader, Col, InputNumber, Row, Select,
} from 'antd';
import {useEffect, useState} from 'react'
import googleIcon from "@/assets/images/login/谷歌logo 1.png"
import background from "@/assets/images/login/Background.png"
import logoHeader from "@/assets/images/header/logoHeader.png"
import clear from "@/assets/images/login/X Icon.png"
import {http} from '@/utils'
import './index.scss'
import {useStore} from '@/store'
import {observer} from "mobx-react-lite";


function AuthComponent({children}) {
    const {loginStore} = useStore()
    const [form] = Form.useForm();
    const [forms] = Form.useForm();
    const userName = Form.useWatch(['user', 'username'], form);
    const searchParams = new URLSearchParams(window.location.search);

    //登录注册的表单'
    const userNames = Form.useWatch(['username'], forms);
    const password = Form.useWatch(['password'], forms);
    const code = Form.useWatch(['code'], forms);

    // const [name, setname] = useState(123)
    const [timeData, setTimeData] = useState(0);
    const {Option} = Select;
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation()
    // 这里是当前浏览器上的路径地址
    const selectedKey = location.pathname
    const isHeader = []
    //记录token
    const [token, setToken] = useState('')
    // const isToken = getToken()
    const spinning = false
    //短信验证码登录
    const [stateType, setStateType] = useState(1)
    //国家列表
    const [countryList, setCountryList] = useState([])
    //发送邮件倒计时
    const [count, setCount] = useState(0)
    //创建用户
    const business = [
        {
            label: 'Law firm',
            value: 'Law firm'
        },
        {
            label: 'Corporation/Brand owner',
            value: 'Corporation/Brand owner'
        },
        {
            label: 'Service provider',
            value: 'Service provider',
        },
        {
            label: 'Academic',
            value: 'Academic',
        },
        {
            label: 'Other(specify)',
            value: 'Other(specify)',
        }
    ]
    //账号密码登录
    const onFinish = async (values) => {
        if (stateType == 1) {
            const res = await http.get("/auth/oauth/token", {
                params: {
                    checkCode: 'n',
                    code: '',
                    // 随机码（自动生成一串随机码）  后台传的参
                    token: token,
                    grant_type: 'password',
                    scope: 'server',
                    authType: 'image',
                    ...values
                }, headers: {
                    Authorization: "Basic aXBpeGVsOmlwaXhlbA=="
                }
            },)
        }

    };
    const [timer, setTimer] = useState(null)
    //询问多账号登录问题
    const [openTitle, setOpenTitle] = useState(false)
    //创建账户
    const onCreateFinish = async (values) => {
        const user = encryption({
            data: values.user,
            key: 'ipixelipixel6666',
            param: ['password']
        });
        const res = await http.post('/auth/user/regist', qs.stringify({
            password: user.password,
            openId: '',
            nickName: '',
            grant_type: "mobile",
            scope: "server",
        }), {
            params: {
                username: values.user.username,
                email: values.user.username,
                code: values.user.code,
                firstName: values.user.firstName,
                lastName: values.user.lastName,
                country: values.user.country,
                companyName: values.user.companyName,
                industry: values.user.industry,
                token: token,
                authType: "email"
            }
        }).then(({data}) => {
            if (data.code == 0) {
                messageApi.open({
                    type: 'success',
                    content: 'login was successful',
                });
                setStateType(1)
            } else {
                messageApi.open({
                    type: 'error',
                    content: data.msgEn,
                });
            }
        }).catch(err => {
            message.error(err.response.data.msg)
        })
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    //已注册返回登录页
    const login = () => {
        setStateType(1)
    }
    useEffect(() => {
        return () => {
            if (timer) clearInterval(timer)
        }
    }, [timer])
    useEffect(() => {
        forms.resetFields();
        if (timer) clearInterval(timer)
    }, [stateType])
    useEffect(() => {
        setStateType(1)
    },[loginStore.open])
    //谷歌登录
    useEffect(() => {
        if(queryURLParameter('state') && queryURLParameter('state') == 'GOOGLE'){
            if(getCookie('auth')) return
            http({
                url: '/auth/oauth/token',
                headers: {
                    Authorization: "Basic c29jaWFsOnNvY2lhbA==",
                },
                method: 'post',
                params: {
                    subject: 'GOOGLE@' + queryURLParameter('code'),
                    code: queryURLParameter('code'),
                    grant_type: "mobile",
                }
            }).then(async data => {
                if (data.status == 200) {
                    if (data.data.access_token) {
                        setCookie('auth', data.data.access_token)
                        loginStore.loginPopup(false)
                        window.open(window.origin,'_self')
                        // window.location.reload();
                    }
                }
            })
                .catch(err => {
                    if (err.response.status == 428) {
                        message.error('Email verification code error');
                    }
                });
        }
    },[])
    useEffect(() => {
        http.get('/trademark/country/list').then(({data}) => {
            if (data.code == 0) {
                setCountryList(data.data)
            }
        })
    }, [])
    useEffect(() => {
        if (timeData !== 0) {
            setTimeout(() => {
                setTimeData(timeData - 1);
            }, 1000)
        }
    }, [timeData]);
    const getEmail = async () => {
        if (!token) return;
        setTimeData(60);
        const res = await http.get("/common/code/email", {
            params: {
                subject: stateType == 4 ? userName : userNames,
                // 随机码（自动生成一串随机码）  后台传的参
                token: token,
                locale: "en",
                bizType: stateType == 4 ? 1 : undefined
            }
        },)
        if (res.data.code == 0) {
            messageApi.open({
                type: 'success',
                content: res.data.msg,
            });
        } else {
            messageApi.open({
                type: 'error',
                content: 'You are not yet a Lanterfish user, please register first',
            });
        }

    }
    useEffect(() => {
        getEmail()
    }, [token])
    const send = async () => {
        if (stateType == 2) {
            if (!userNames) {
                messageApi.open({
                    type: 'error',
                    content: 'Email cannot be empty',
                });
                return;
            }
        } else if (stateType == 4) {
            if (!userName) {
                messageApi.open({
                    type: 'error',
                    content: 'Email cannot be empty',
                });
                return;
            }
        }
        setToken(Math.random())
    }
    const onGenderChange = (value) => {
    }
    const handleOk = () => {
        onLoginFinish(true)
    };
    const handleCancel = () => {
        setOpenTitle(false)
    };
    const SqueezeOutLanding = () => {
        setOpenTitle(true)
    }

    const clearData = () => {
        forms.setFieldsValue({
            username: ''
        });
    }

    const Google = () => {
        http.get("/admin/justauth/authorisation/google").then(({data}) => {
            if(data.code == 0){
                window.open(data.data,'_self')
            }
            console.log(data,"data")
        })
    }

    //密码修改
    const RSetpasswordSuccess = (data) => {
        http.post('/auth/user/retrieve', {
            authType: "email",
            password: password,
            token: data,
            username: userNames
        }).then(({data}) => {
            console.log(data,"data")
            if (data.code == 0 && data.data) {
                message.success('Password modification successful')
                setStateType(1)
            } else {
                message.error('Password change failed');
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    //修改密码
    const onModify = () => {
        http({
            url: '/auth/user/check',
            method: 'post',
            params:{
                username: userNames,
                code: code,
                token: token,
                authType: "email"
            },
            data:{
                username: userNames,
                code: code,
                token: token,
                authType: "email"
            }
        }).then(({data}) => {
            if (data.code == 0) {
                RSetpasswordSuccess(data.data)
            }else {
                this.$message({
                    message: data.msg
                });
            }
        })
            .catch(err => {
            });
    }

    //账号密码登录
    const onLoginFinish = (data) => {
        clearCookie('auth')
        if(stateType == 1){
            http.get("/auth/oauth/token", {
                params: {
                    checkCode: 'n',
                    username: userNames,
                    password: password,
                    code: '',
                    token: Math.random(),
                    grant_type: 'password',
                    scope: 'server',
                    authType: 'image',
                    kick: data ? 1 : undefined
                },
                headers: {
                    Authorization: "Basic aXBpeGVsOmlwaXhlbA=="
                }
            }).then((data) => {
                if (data.status == 200) {
                    if (data.data.access_token) {
                        setCookie('auth', data.data.access_token)
                        loginStore.loginPopup(false)
                        window.location.reload();
                    } else {
                        SqueezeOutLanding()
                        return;
                    }
                }
            })
                .catch(err => {
                    messageApi.open({
                        type: 'error',
                        content: 'Username does not exist or password is wrong'
                    })
                });
        }else if(stateType == 2){
            // 直接用手机表单登录接口
            http({
                url: '/auth/oauth/token',
                headers: {
                    Authorization: "Basic YXBwOmFwcA=="
                },
                method: 'post',
                params: {
                    subject: 'EMAIL@' + userNames,
                    username: userNames,
                    code: code,
                    token: token,
                    grant_type: "mobile",
                    authType: "email"
                }
            }).then(async data => {
                if (data.status == 200) {
                    if (data.data.access_token) {
                        setCookie('auth', data.data.access_token)
                        loginStore.loginPopup(false)
                        window.location.reload();
                    }
                }
            })
                .catch(err => {
                    if (err.response.status == 428) {
                        message.error('Email verification code error');
                    }
                });
        }
    }

    if (isHeader.includes(selectedKey)) {
        return <div className={'lodaing-box'}>
            {contextHolder}
            <Spin indicator={<Loading/>} spinning={loginStore.loading} >
                {children}
            </Spin>
        </div>
    } else {
        return <div className={'lodaing-box'}>
            {contextHolder}
            <Header/>
            <Spin indicator={<Loading/>} spinning={loginStore.loading} >
                {children}
            </Spin>
            {/*登录注册弹框*/}
            <Modal
                wrapClassName={'Modal_login'}
                title=""
                centered
                open={loginStore.open}
                labelCol={{span: 8}}
                wrapperCol={{span: 24}}
                onOk={() => loginStore.loginPopup(false)}
                onCancel={() => loginStore.loginPopup(false)}
                footer={
                    [] // 设置footer为空，去掉 取消 确定默认按钮
                }
                width={608}
                className={'login'}
            >
                {
                    stateType == 4 ?
                        <div className={'createUser'} style={{'textAlign': 'center'}}>
                            <div className={'login_name'}>
                                Create your <span>Free</span> Account
                            </div>
                            <Form
                                form={form}
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{width: '495px'}}
                                name="nest-messages"
                                onFinish={onCreateFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item name={['user', 'username']}  rules={[{
                                    required: true,
                                    message: 'Enter mail',
                                }, {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                }]}>
                                    <Input placeholder={'Email'} style={{
                                        height: '60px', border: '1px solid #333333',
                                        borderRadius: '10px 10px 10px 10px'
                                    }}/>
                                </Form.Item>

                                <Form.Item name={['user', 'password']} hasFeedback  rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}>
                                    <Input.Password placeholder={'Password'} style={{
                                        height: '60px', border: '1px solid #333333',
                                        borderRadius: '10px 10px 10px 10px'
                                    }}/>
                                </Form.Item>
                                <div style={{display: 'flex'}}>
                                    <Form.Item style={{width: '600px'}} name={['user', 'code']} rules={[{
                                        required: true,
                                        message: 'Enter verification code',
                                    }]}>
                                        <Input style={{
                                            height: '60px', border: '1px solid #333333',
                                            borderRadius: '10px 10px 10px 10px'
                                        }} placeholder={'Email verification code'}/>

                                    </Form.Item>
                                    <Button style={{marginLeft: '10px', height: '60px', width: '61px'}} disabled={timeData !== 0}
                                            type="primary" onClick={send}>{timeData !== 0 ? timeData : 'send'}</Button>
                                </div>
                                <Form.Item style={{textAlign: 'left'}} name="remember" valuePropName="checked"
                                           rules={[
                                               {
                                                   validator: (_, value) =>
                                                       value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                               },
                                           ]}
                                >
                                    <Checkbox><a href="#/ServiceTreaty">I accept the Term of service.</a></Checkbox>
                                </Form.Item>
                                <Form.Item    className={'form_submit'}>
                                    <Button className={'submit'} type="primary" htmlType="submit">
                                        Create Account
                                    </Button>
                                </Form.Item>
                                <Form.Item >
                                    <Divider style={{margin: 0, color: '#AAA9A9'}} plain>Or</Divider>
                                </Form.Item>
                                <Form.Item    className={'form_submit'}>
                                    <Button onClick={Google} className={'Google'} type="primary"><img style={{marginRight: '5px'}} src={googleIcon} alt=""/>Sign in with Google</Button>
                                </Form.Item>
                                <Form.Item style={{marginTop: '20px'}} name="remember" valuePropName="checked">
                                    <p style={{cursor: 'pointer'}} onClick={login}>Have an account? <span style={{color: '#4461F2', marginLeft: '5px'}}>Sign in</span></p>
                                </Form.Item>
                            </Form>
                        </div>
                        :
                        <div className={'logo_content'}>
                            <div className={'login_name'}>
                                {stateType == 3 ? 'Reset Password' : 'Sign in to Lanternfish'}
                            </div>
                            <div className={'right'}>
                                <Form
                                    form={forms}
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    style={{width: '495px'}}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={stateType == 3 ? () => onModify() : () => onLoginFinish()}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input size="large" style={{
                                             height: '60px', border: '1px solid #333333',
                                            borderRadius: '10px 10px 10px 10px'
                                        }} placeholder="Email" suffix={
                                            <img onClick={clearData} style={{cursor: 'pointer'}} src={clear} alt=""/>
                                        }/>
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                min: 6,
                                                message: 'Please set a password of more than 6 digits',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder={stateType == 3 ? 'New Password' : 'Password'} style={{
                                            height: '60px', border: '1px solid #333333',
                                            borderRadius: '10px 10px 10px 10px'
                                        }} size="large"/>
                                    </Form.Item>
                                    {
                                         stateType == 3 ?
                                            <div className={'sending'}>
                                            <Form.Item
                                                name="code"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter verification code',
                                                    },
                                                ]}
                                            >
                                                    <Input placeholder={'Email verification code'}  style={{
                                                        width: '425px', height: '60px', border: '1px solid #333333',
                                                        borderRadius: '10px 10px 10px 10px'
                                                    }} size="large"/>



                                            </Form.Item>
                                                <Button className={'send_mess'} onClick={send} type="primary" disabled={timeData !== 0}
                                                >{timeData !== 0 ? timeData : 'send'}</Button>
                                            </div>: null
                                    }
                                    {
                                        stateType == 3 ? <Form.Item
                                                className={'form_submit'}
                                            >
                                                <Button className={'submit'} type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>:
                                            <>
                                                <Form.Item>
                                                    <p onClick={() => setStateType(3)} className={'recover'}>Recover Password ?</p>
                                                </Form.Item>
                                                <Form.Item
                                                    className={'form_submit'}
                                                >
                                                    <Button className={'submit'} type="primary" htmlType="submit">
                                                        Sign In
                                                    </Button>

                                                    <Form.Item
                                                        className={'account_item'}
                                                    >
                                                        <p onClick={() => setStateType(4)} className={'account'}><span style={{color: '#AAA9A9', marginRight: '5px'}}>Don’t have an account?</span>create an account</p>
                                                    </Form.Item>
                                                </Form.Item>
                                                <Form.Item >
                                                    <Divider style={{margin: 0, color: '#AAA9A9'}} plain>Or</Divider>
                                                </Form.Item>
                                                <Form.Item    className={'form_submit'}>
                                                    <Button onClick={Google} className={'Google'} type="primary"><img style={{marginRight: '5px'}} src={googleIcon} alt=""/>Sign in with Google</Button>
                                                </Form.Item>
                                            </>
                                    }

                                </Form>
                            </div>
                        </div>
                }
            </Modal>
            <Modal
                centered
                title="Tips"
                open={openTitle}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>This account has been logged in. Do you want to exit other logins？</p>
            </Modal>
        </div>
    }
}

// <AuthComponent> <Layout/> </AuthComponent>
// 登录：<><Layout/></>
// 非登录：<Navigate to="/login" replace />

export default observer(AuthComponent)

