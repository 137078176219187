//登陆模块
import { makeAutoObservable } from "mobx"
import { http, setCookie, getCookie, clearCookie } from "@/utils";

class LoginStore {
    token = getCookie('auth') || ''
    open = false
    loading = false
    constructor() {
        makeAutoObservable(this)
    }
    //登陆
    login = async ({ mobile, code }) => {
        const res = await http.post('http://geek.itheima.net/v1_0/authorizations',{
            mobile,
            code
        })
        this.token = res.data.token
        setCookie('auth',res.data.token)
    }
    //退出登陆
    loginOut = () => {
        this.token = ''
        clearCookie('auth')
    }
    //登录弹框控制
    loginPopup = (value) => {
        this.open = value
    }
    //控制loading
    loadEven = (value) => {
        this.loading = value
    }
}

export default LoginStore
