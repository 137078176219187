import axios from 'axios'
import { history } from './history'
import {setCookie, getCookie, clearCookie } from './index'
import ReactDOM from 'react-dom/client';
import { message, Spin } from 'antd';
import eventBus from "./eventBus"
import Loading from "@/components/Loading";

// 当前正在请求的数量
let requestCount = 0

// 显示loading
function showLoading () {
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        // ReactDOM.render(<Spin tip="加载中..."  size="large"/>, dom)

        const root = ReactDOM.createRoot(document.getElementById('loading'));
        root.render(
            <Spin tip="加载中..." indicator={<Loading/>}  size="large"/>
        );
    }
    requestCount++
}

// 隐藏loading
function hideLoading () {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}

const http = axios.create({
    baseURL: '/api',
    timeout: 125 * 1000
})
let loaingArr = [
    '/order/payment/pay',
    '/order/order/createLanternfishOrder',
    '/trademark/monitoring/exportManyCountryMonitoringDetail',
    '/common/query/idListQuery',
    '/common/query/invalidNoticeExcelDownload',
    '/common/query/getInvalidNotice',
    '/trademark/monitoringRenewal/batch/update/amount',
    '/trademark/monitoringRenewal/batch/save',
    '/trademark/monitoringRenewal/page',
    '/trademark/monitoring/createMonitoringImageOrWord',
    '/trademark/iphouseApi/ReviewCaseDetail',
    '/trademark/iphouseApi/judgementDocumentDetail',
    '/trademark/iphouseApi/courtNoticeDetail',
    '/lantern/lanresource/list',
    '/trademark/iphouseApi/judgementDocumentDetail',
    '/trademark/iphouseApi/reviewCase',
    '/trademark/iphouseApi/judgementDocument',
    '/trademark/iphouseApi/courtNotice',
    '/trademark/iphouseApi/reviewCaseAdvanced',
    //商标对比xls下载
    '/common/query/tmCompare/excelExport',
    //获取订购购买服务列表接口
    '/product/productservicemanage/getPrePublishServiceList',
    //商标对比
    '/common/query/tmCompare',
    //上传文档商标对比接口
    '/lantern/api/trademark/ocr/officalfile',
    //获取公告多页pdf
    '/common/query/getNoticePages',
    //获取公告概况信息
    '/common/query/getNoticOverView',
    //获取公告数据（带公告pdf）
    '/common/query/getNoticeInfo',
    //商标查询
    //  '/common/query/groupStatistics',
    '/trademark/monitoring/getUserMonitorings',
    //类似群组查询接口
    '/common/query/similargroup',
    //添加商标监控
    '/trademark/monitoring/createMonitoring',
    '/trademark/trademark/generateUrl',
    //商品分类
    '/common/query/tmNice',
    //商标查询xls导出
    '/common/query/excelExport',
    //商标查询word导出
    '/common/query/wordExportForLantern',
    //获取近似群组
    '/common/query/tmCrossSearch',
    //商标监控删除
    '/trademark/monitoring/delMonitoringTask',
    //图片上传接口
    '/admin/sys-file/upload',
    //监控详情结果导出
    '/trademark/monitoring/monitoringResultExport',
    //获取监控列表页详情
    '/trademark/monitoring/getUserMonitoringDetails',
    //商标监控报告导出
    '/trademark/monitoring/userMonitoringExport',
    //商标监控结果列表
    '/trademark/monitoring/getUserMonitoringReport',
    //商标监控列表
    '/trademark/monitoringcountry/queryAllCountry',
    '/common/query/countryStatisic',
    '/patent/excel/appNosOrPubNosExcelImport',
    '/common/query/countryStatisicForLantern',
    '/common/query/goodsServiceForLantern',
    '/common/query/appAndRegForLantern',
    '/common/query/countryStatisicAllForLantern',
    '/order/invoicetitle',
    '/lantern/jurisdiction/getTrademarkServiceAuthority',
    '/lantern/translateLan/verificationDoc',
    '/lantern/translateLan/docTanslate',
    '/patent/search/getVecSimilarWord',
    '/patent/search/searchAggregation',
    '/patent/search/searchPatent',
    '/patent/excel/dimensionExcelExport',
    '/patent/download/searchPatentReport',
    '/common/query/searchByIds',
    '/common/query/search/v2',
    '/common/graph/search/v2',
    '/common/query',
    '/common/query/analyzeSuccessRate',
    '/admin/contact/getContact',
    '/product/servicemanage/getUserServiceManages',
    '/order/order/getOrderLanternfishPage',
    '/lantern/lanlog/page',
    '/common/file',
    '/patent/download/exportReport',
    '/admin/article/getArticleList',
    '/admin/article/getArticleRecord'
];
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
//
http.defaults.headers.common['TENANT-ID'] = 6;

//添加请求拦截器
http.interceptors.request.use((config) => {
    let responseURL = config.url.trim();
    //判断请求的接口是否需要loading
    if (loaingArr.includes(responseURL)) {
        showLoading()
    }

    const token = getCookie('auth')
    if(token){
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
},(error) => {
    return Promise.reject(error)
})

//添加相应拦截器
http.interceptors.response.use((response) => {
    //2xx 范围内额状态码都会触发该函数
    //对响应数据做点什么
    //获取接口名和参数的数组
    let responseURL = response.config.url ? response.config.url.trim().split("?")[0] : "";
    //取消loading
    if (loaingArr.includes(responseURL)) {
        hideLoading()
    }
    return response
}, (error) => {
    // 超出 2xx范围的状态都会触发该函数
    // 对响应错误做点什么
    hideLoading()
    if(error.response.status === 401){
        //删除token
        clearCookie('auth')
        eventBus.emit('open',true)
    }else   if(error.response.status === 424){
        //删除token
        clearCookie('auth')
        eventBus.emit('open',true)
        // message.error('Go log in')
    }
    return Promise.reject(error)
})

export { http }
