import './index.scss'
function Loading() {
    return(
        <div className="container">
            <div className="spinner">
                <div className="dot1"></div>
                <div className="dot2"></div>
            </div>
        </div>
    )
}

export default Loading
